// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BottomMenu_bottomMenu__2Ppww {
    position: fixed;
    z-index: 2;
    left: 50%;
    transform: translate(-50%);
    width: 100vw;
    bottom: 0;
    height: 50px;
    background-color: #A47864;
    color: #f0f0f0;
    max-width: 460px;
    padding-top: 15px;
}

.BottomMenu_bottomMenu__2Ppww a {
    color: #f0f0f0;
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/style/BottomMenu.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,UAAU;IACV,SAAS;IACT,0BAA0B;IAC1B,YAAY;IACZ,SAAS;IACT,YAAY;IACZ,yBAAyB;IACzB,cAAc;IACd,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB","sourcesContent":[".bottomMenu {\n    position: fixed;\n    z-index: 2;\n    left: 50%;\n    transform: translate(-50%);\n    width: 100vw;\n    bottom: 0;\n    height: 50px;\n    background-color: #A47864;\n    color: #f0f0f0;\n    max-width: 460px;\n    padding-top: 15px;\n}\n\n.bottomMenu a {\n    color: #f0f0f0;\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bottomMenu": `BottomMenu_bottomMenu__2Ppww`
};
export default ___CSS_LOADER_EXPORT___;
