import React, { createContext, useContext, useState, ReactNode } from 'react';

export interface UserProfileProps {
    id: number,
    level: number,
    avatar: string,
    win: number,
    lost: number,
    draw: number,
    attributes: {
        str: number;
        dur: number;
        power: number;
        speed: number;
    };
    updatePoints: number;
    exp: number;
    lastHpAction: number
    maxHP: number;
    HP: number;
}

interface UserProps {
    id: number,
    name: string,
}
// Define the shape of the context state
interface GlobalContextProps {
    setUserId: (userId: string) => void;
    setUserProfile: (profile: any)  => void;
    setUser: (user: any)  => void;
    user: UserProps;
    userId: string;
    userProfile: UserProfileProps;
}

// Create the context
const GlobalContext = createContext<GlobalContextProps | undefined>(undefined);

// Custom hook to use the context
export const useGlobalContext = () => {
    const context = useContext(GlobalContext);
    if (!context) {
        throw new Error('useGlobalContext must be used within a GlobalProvider');
    }
    return context;
};

// Provider component
export const GlobalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const storedUserProfile = localStorage.getItem("userProfile");
    const initialUserProfile = storedUserProfile ? JSON.parse(storedUserProfile) : { id: 0, level: 0, avatar: 'avatar1' };

    const [userId, setUserId] = useState<string>('');
    const [userProfile, setUserProfile] = useState<UserProfileProps>(initialUserProfile);
    const [user, setUser] = useState<UserProps>({ id:0, name:''});

    return (
        <GlobalContext.Provider value={{ userId, setUserId, userProfile, setUserProfile, user, setUser }}>
            {children}
        </GlobalContext.Provider>
    );
};
