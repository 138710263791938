import React from 'react';
import {Link} from "react-router-dom";
import styles from "./../style/BottomMenu.module.css";

const BottomMenu = () => {
    return (
        <div className={styles.bottomMenu}>
            <Link to="/">Home</Link> | <Link to="/battles">Battles</Link> | <Link to="/hero">Hero</Link> | <Link to="/wallet">Wallet</Link>
        </div>
    )
}

export default BottomMenu;