import React, {useState} from 'react';
import { useGlobalContext } from './GlobalContext';
import styles from "./style/Battle.module.css";

interface HeroProps {
    profileId: string;
}

interface UserProfileChanges {
    attributes: {
        str: number;
        dur: number;
        power: number;
        speed: number;
    };
    avatar: string;
}

const API_URL = process.env.REACT_APP_API_URL;

const Hero: React.FC<HeroProps> = ({ profileId }) => {
    const { userId } = useGlobalContext();
    const { userProfile, setUserProfile } = useGlobalContext();

    const [changes, setChanges] = useState<UserProfileChanges>({
        attributes: { ...userProfile.attributes },
        avatar: userProfile.avatar,
    });

    let allowEditing = false;

    if (profileId === userId) {
        allowEditing = true;
    }

    // Handle changes to attribute values (increase/decrease)
    const handleAttributeChange = (attribute: keyof UserProfileChanges['attributes'], operation: 'increase' | 'decrease') => {
        setChanges((prev) => {
            const newValue = operation === 'increase'
                ? prev.attributes[attribute] + 1
                : prev.attributes[attribute] - 1;

            return {
                ...prev,
                attributes: {
                    ...prev.attributes,
                    [attribute]: newValue >= 0 ? newValue : 0, // Ensure it doesn't go below 0
                },
            };
        });
    };

    // Handle avatar change
    const handleAvatarChange = (event: { target: { value: any; }; }) => {
        const { value } = event.target;
        setChanges(prevState => ({
            ...prevState,
            avatar: value
        }));
    };

    // Handle form submission (simulated)
    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();

        try {
            const storedToken = localStorage.getItem('token');
            if (storedToken) {
                const response = await fetch(API_URL + "/profile/update", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': storedToken
                    },
                    body: JSON.stringify(changes),
                    credentials: 'include'
                });
                if (!response.ok) {

                    let errorMessage = 'Something went wrong while setting session data';
                    try {
                        const errorData = await response.json();
                        errorMessage = errorData.message || errorMessage;
                    } catch (jsonError) {
                        console.error("Error parsing JSON response:", jsonError);
                    }
                    throw new Error(errorMessage);
                }
                else {
                    const responseData = await response.json();
                    setUserProfile(responseData);
                }
            }
        } catch
            (error) {
            if (error instanceof TypeError) {
                console.log(API_URL);
                // Network or other Fetch-related errors
                console.error("Network or Fetch error:", error.message);
            } else {
                // Custom error messages
                console.error("Error setting session data:", error);
            }
        }

};

    if (allowEditing) {
        return (
            <div className={styles.battleContainer}>
                editing
                <form onSubmit={handleSubmit}>

                    {/* Editable fields with buttons for increase/decrease */}
                    {['str', 'dur', 'power', 'speed'].map((attribute) => (
                        <div key={attribute}>
                            <label>{attribute.charAt(0).toUpperCase() + attribute.slice(1)}:</label>
                            <button
                                type="button"
                                onClick={() => handleAttributeChange(attribute as keyof UserProfileChanges['attributes'], 'decrease')}
                                disabled={changes.attributes[attribute as keyof UserProfileChanges['attributes']] <= 0}
                            >
                                -
                            </button>
                            <span>{changes.attributes[attribute as keyof UserProfileChanges['attributes']]}</span>
                            <button
                                type="button"
                                onClick={() => handleAttributeChange(attribute as keyof UserProfileChanges['attributes'], 'increase')}
                            >
                                +
                            </button>
                        </div>
                    ))}

                    {/* Avatar selection */}
                    <div>
                        <label>Avatar:</label>
                        <select value={changes.avatar} onChange={handleAvatarChange}>
                            {/* Generate avatar options dynamically from avatar1 to avatar10 */}
                            {[...Array(10)].map((_, index) => {
                                const avatarId = index + 1;
                                return (
                                    <option key={avatarId} value={`avatar${avatarId}`}>
                                        Avatar {avatarId}
                                    </option>
                                );
                            })}
                        </select>
                    </div>

                    {/* Submit button */}
                    <button type="submit">Update Profile</button>
                </form>
            </div>
        )
    }

    return (
        <div className={styles.battleContainer}>
        </div>
    )
}

export default Hero;