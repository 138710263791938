import React, { useState, useEffect } from 'react';
import './style/Profile.css';
import { useGlobalContext } from './GlobalContext';
import Avatar from "./Avatar";
import BattleHistory from "./user/BattleHistory";


const Profile : React.FC = () => {
    const { userId } = useGlobalContext();
    const { userProfile } = useGlobalContext();
    const { user } = useGlobalContext();

    useEffect(() => {

    });

    const battlesNumber = userProfile.win + userProfile.lost + userProfile.draw;

    return (
        <div className="profile-page">
            <div className="stats">
                <div className="stat">
                    <p className="stat-number">{battlesNumber}</p>
                    <p className="stat-label">Battles</p>
                </div>
                <div className="stat">
                    <p className="stat-number">{userProfile.win}/{userProfile.lost}/{userProfile.draw}</p>
                    <p className="stat-label">Wins/Losses/Draws</p>
                </div>
                <div className="stat">
                    <p className="stat-number">1</p>
                    <p className="stat-label">Ranking</p>
                </div>
            </div>
            <div className="battle-history">
                <BattleHistory userId={+userId} />
            </div>
        </div>
    );
}
export default Profile;