import React, { useState, useEffect } from 'react';
import {UserProfileProps } from '../GlobalContext';

interface HealthBarProps {
    userProfile: UserProfileProps;
}

const HealthBar = ({ userProfile }: HealthBarProps) => {
    const { HP, maxHP, lastHpAction } = userProfile;
    const restoreRate = 1; // 2% per second
    const barHeight = 5; // in pixels

    console.log(userProfile.lastHpAction);
    const [currentHP, setCurrentHP] = useState(HP);

    useEffect(() => {
        const calculateElapsedHP = () => {
            const now = Date.now() / 1000; // Convert milliseconds to seconds
            const elapsedTime = now - lastHpAction; // Elapsed time in seconds
            console.log(`${elapsedTime}s`);
            const restoredHP = Math.min(maxHP, HP + elapsedTime * (maxHP * restoreRate / 100));
            return Math.floor(restoredHP);
        };

        // Update HP on mount
        setCurrentHP(calculateElapsedHP());

        const interval = setInterval(() => {
            setCurrentHP((prevHP: number) => {
                if (prevHP < maxHP) {
                    return Math.min(maxHP, prevHP + maxHP * restoreRate / 100);
                }
                return maxHP; // Ensure HP doesn't exceed maxHP
            });
        }, 1000); // 1-second interval for HP restoration

        return () => clearInterval(interval); // Cleanup on component unmount
    }, [HP, maxHP, lastHpAction]);

    const hpPercentage = (currentHP / maxHP) * 100;

    return (
        <div
            style={{
                position: 'relative',
                height: `${barHeight}px`,
                width: '100%',
                backgroundColor: 'black',
                borderRadius: '4px',
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    height: '100%',
                    width: `${hpPercentage}%`,
                    backgroundColor: 'green',
                    transition: 'width 1s linear', // Smooth transition
                }}
            />
            <div className="user-hp-numbers">
                {currentHP.toFixed(2)}/{maxHP}
            </div>
        </div>
    );
};

export default HealthBar;