import styles from "../style/TopMenu.module.css";
import React from "react";
import {useGlobalContext} from "../GlobalContext";
import Avatar from "../Avatar";
import {Link} from "react-router-dom";
import HealthBar from "../user/HealthBar";


const TopMenu = () => {
    const { userProfile } = useGlobalContext();
    const { user } = useGlobalContext();
    const extractNumberFromAvatar = (avatar: string) => {
        return avatar.replace(/\D/g, ''); // Replace all non-digit characters with an empty string
    };

    const avatarNumber = extractNumberFromAvatar(userProfile.avatar);

    return (
        <div className={styles.TopMenu}>
            <div className="user-info">
                <div className="user-avatar-and-hp">
                    <div className="user-avatar">
                        <Link to="/hero"><Avatar avatarNumber={avatarNumber}/></Link>
                    </div>
                    <div className="user-hp">
                        <HealthBar userProfile={userProfile}></HealthBar>
                    </div>
                </div>
                <div className="user-params">
                    <div className="user-name-wapper">
                        <h1 className="username">{user.name}</h1>
                    </div>
                    <div className="user-details">
                        <div>
                            <label>Level: </label>
                            <span>{userProfile.level}</span>
                        </div>
                        <div>
                            <label>Experience: </label>
                            <span>{userProfile.exp}/100</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default TopMenu;